<template>
  <div class="py-5">
    <div class="container">
      <div class="row">
        <div class="col-sm-9">
          <div class="bg-light py-5 border mb-3">
            <div class="container text-center" v-if="!loading">
              <small class="text-muted"
                >Publier ce: {{ offre.dateEff || dayjs("DD-MM-YYYY") }} | Date écheance:
                {{ offre.dateEch || dayjs("DD-MM-YYYY") }}
              </small>
              <!--<small class="text-muted"
                >Postes: 03 | Sam 12, 2022 |
                <span class="text-primary">JS Com</span></small
              >-->
              <h2 class="mb-3">{{ offre.intitule }}</h2>
              <p class="" v-html="offre.descriptionTache"></p>
              <router-link
                @click.prevent="postuerOffre"
                class="style_btn btn btn-success btn-lg"
              >
                Postuler
              </router-link>
            </div>
          </div>
          <div class="bg-light py-5 border mb-3">
            <div class="container text-center">
              <!--<h4 class="mb-4">Compétances recherché</h4>-->
              <h4 class="mb-4">Domaine</h4>
              <div class="py-3 d-flex flex-wrap justify-content-center">
                <div v-for="(domaine, index) in offre.domaines" :key="index">
                  <span class="badge bg-primary text-white rounded-pill px-4 me-3 mb-3"
                    ><h6 class="m-0">{{ domaine.description }}</h6></span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="bg-light py-5 border mb-3">
            <div class="container text-center">
              <h4 class="mb-4">Autres Information</h4>
              <div class="row text-start">
                <div class="col-sm-4 mb-4">
                  <div class="border p-2 h-100">
                    <p class="my-0 fw-bold">Poste</p>
                    <p class="text-muted my-0" v-if="offre.poste">
                      {{ offre.poste.libelle }}
                    </p>
                  </div>
                </div>
                <div class="col-sm-4 mb-4">
                  <div class="border p-2 h-100">
                    <p class="my-0 fw-bold">Nature du contrat</p>
                    <div>
                      <p class="text-muted my-0" v-if="offre.nature">
                        {{ offre.nature.code }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4 mb-4">
                  <div class="border p-2 h-100">
                    <p class="my-0 fw-bold">Années d'expériences</p>
                    <p class="text-muted my-0">{{ offre.annExp }} ans</p>
                  </div>
                </div>
                <!--<div class="col-sm-4 mb-4">
                  <div class="border p-2 h-100">
                    <p class="my-0 fw-bold">Langues</p>
                    <div v-for="(lang, index) in offre.langues" :key="index">
                      <p class="text-muted my-0">{{ lang.code }}</p>
                    </div>
                  </div>
                </div> -->
                <div class="col-sm-4 mb-4">
                  <div class="border p-2 h-100">
                    <p class="my-0 fw-bold">Niveau d'étude</p>
                    <div v-for="(niv, index) in offre.niveaus" :key="index">
                      <p class="text-muted my-0" v-if="niv.code">
                        {{ niv.code }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4 mb-4">
                  <div class="border p-2 h-100">
                    <p class="my-0 fw-bold">Lieu</p>
                    <p class="text-muted my-0">{{ offre.lieuDeDepot }}</p>
                  </div>
                </div>
                <div class="col-sm-4 mb-4">
                  <div class="border p-2 h-100">
                    <p class="my-0 fw-bold">Pièces</p>
                    <div v-for="(piece, index) in offre.typePieces" :key="index">
                      <p class="text-muted my-0">{{ piece.libelle }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <router-link
          @click.prevent="postuerOffre"
          class="style_btn btn btn-block btn-success btn-lg"
        >
          Postuler a cette offre
        </router-link>

        <!--<div class="col-sm-3">
          <h4 class="card-title mb-3">Autres offres</h4>
          <div class="border rounded-3 bg-white mb-2 px-2 py-2">
           
              <div
                class="d-flex align-items-center"
                v-for="(offre, index) in offres"
                :key="index"
              >
               <router-link
              :to="{
                name: 'portail.offre.details',
                params: { offreSlug: offre.id },
              }"
            >
                <div
                  class="flex-shrink-0 d-flex justify-content-center align-items-center"
                >
                  <div
                    class="bg-primary style_level_offre d-flex flex-column justify-content-center align-items-center"
                  >
                    <h6 class="my-0 text-white">{{ index }}</h6>
                    <span class="text-white my-0" style="font-size: 8px">
                      {{ offre.poste }}</span
                    >
                  </div>
                </div>
                <div class="flex-grow-1 ms-2">
                  <p class="mb-0">
                    <small v-html="offre.intitule"> </small>
                  </p>
                </div>
                  </router-link>
              </div>
          
          </div>
        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { paginatorMixin } from "@/mixins/paginator-mixin";
import "@fullcalendar/core/vdom"; // solves problem with Vite
// import FullCalendar from '@fullcalendar/vue'
// import ActiviteEditor from '../../../components/espace/common/ActiviteEditor.vue'

export default {
  components: {
    // FullCalendar,
  },
  mixins: [paginatorMixin],
  props: ["offreSlug"],
  data() {
    return {
      // offre:{...this.offreSlug},
      loading: false,
      viewModes: [
        { icon: "pi pi-align-left", value: "left" },
        { icon: "pi pi-align-right", value: "Right" },
        { icon: "pi pi-align-justify", value: "Justify" },
      ],

      searchFields: ["nom"],
      searchFieldsOptions: [
        {
          label: "Bénéficiaire",
          value: "nom",
          visible: this.$can("filter.beneficiaire", "Azoli"),
        },
        {
          label: "Téléphone",
          value: "phone",
          visible: this.$can("filter.telephone", "Azoli"),
        },
        {
          label: "Whatsapp",
          value: "whatsapp",
          visible: this.$can("filter.whatsapp", "Azoli"),
        },
        {
          label: "Commune",
          value: "commune",
          visible: this.$can("filter.commune", "Azoli"),
        },
        {
          label: "Département",
          value: "departement",
          visible: this.$can("filter.departement", "Azoli"),
        },
        {
          label: "AE (Nom & prénom)",
          value: "ae",
          visible: this.$can("filter.ae", "Azoli"),
        },
        {
          label: "Arrondissement",
          value: "arrondissement",
          visible: this.$can("filter.arrondissement", "Azoli"),
        },
        {
          label: "Village/Quartier de ville",
          value: "ville",
          visible: this.$can("filter.ville", "Azoli"),
        },
        {
          label: "Métier Appris",
          value: "metierAppris",
          visible: this.$can("filter.metierAppris", "Azoli"),
        },
        {
          label: "Métier Désiré",
          value: "metierDesire",
          visible: this.$can("filter.metierDesire", "Azoli"),
        },
      ],
      filterOptions: [
        {
          column: "nom",
          value: "",
          label: "Bénéficiaire",
          dropdown: false,
          visible: this.$can("filter.beneficiaire", "Azoli"),
        },
        {
          column: "phone",
          value: "",
          label: "Téléphone",
          dropdown: false,
          visible: this.$can("filter.telephone", "Azoli"),
        },
      ],
      filters: {
        skills: [],
        availabilities: [],
        price_min: null,
        price_max: null,
        departement: null,
        _queryOptions: [
          {
            column: "departement",
            value: null,
            label: "Département",
          },
          {
            column: "commune",
            value: null,
            label: "Commune",
          },
        ],
        ordering: "",
        // _orderingOptions: {
        // }
      },
      activeDemandeur: {},
    };
  },
  async created() {
    this.getOffre(this.offreSlug);
    this.fetchOffres();
  },
  watch: {},
  computed: {
    ...mapGetters({
      offre: "offre/offre",
      offres: "offre/offres",
    }),
    emptyEvent() {
      return {};
    },
  },
  methods: {
    ...mapActions({
      fetchOffre: "offre/getOneOffrePublished",
      fetchOffres: "offre/fetchOffresPublished",
    }),
    postulerOffre() {
      this.$router.push({
        name: "espace.de.postuler.offre",
        params: { offreSlug: offre.id },
      });
    },
    handleDateClick(arg) {
      this.setActiveEvent({
        ...this.emptyEvent,
        datePrevue: arg.dateStr,
        // datePrevue: new Date(this.$dayjs(arg.dateStr).format('YYYY-MM-DDTHH:mm')),
      });
      this.$refs.activiteEditor.show();
    },
    confirm1(event, activite) {
      this.$confirm.require({
        target: event.currentTarget,
        message: "L'activité a-t-elle pu tenir?",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.createOrUpdateActivitePlanifie({
            ...activite,
            statusActivite: "TENUE",
          }).then(() => {
            this.$toast.success("Opération réussie!", {
              position: "top-right",
              duration: 5000,
            });
            this.$refs.activiteStatistic.reload();
          });
        },
        reject: () => {
          this.createOrUpdateActivitePlanifie({
            ...activite,
            statusActivite: "NON_TENUE",
          }).then(() => {
            this.$refs.activiteStatistic.reload();
            // this.$toast.success("Opération réussie!", {
            //   position: "top-right",
            //   duration: 5000
            // })
          });
        },
      });
    },
    getOffre(offre) {
      this.loading = true;
      this.fetchOffre(offre).then(() => {
        console.log(this.offre);
        this.loading = false;
      });
      this.fetchOffres();
    },

    createOrUpdateProgramme(activite) {
      this.createOrUpdateActivitePlanifie(activite).then(() => {});
    },
    showEventParticipants(activite) {
      this.setActiveEvent(activite);
      this.$refs.participantListDialog.show();
    },
    toggleWeekends: function () {
      this.calendarOptions.weekends = !this.calendarOptions.weekends; // toggle the boolean!
    },
  },
};
</script>

<style>
.event-box-pending {
  background: #42a5f5;
  background: #66bb6a;
  background: #26c6da;
  background: #7e57c255;
  background: #7e57c245;
  color: #7e57c2 !important;
}

.event-box-done {
  /* background: #66BB6A45;
     color: #66BB6A!important; */
  background: #00b68345;
  color: #00b683 !important;
  color: rgba(0, 0, 0, 0.57) !important;
}
.event-box-danger {
  background: #ffa72645;
  color: #ffa726ff !important;
  color: rgba(0, 0, 0, 0.57) !important;
}

.event-box-missed {
  /* background: #42A5F545;
     color: #42A5F5FF!important; */
  background: #fe005345;
  /* color: #FE0053FF!important; */
  /* color: rgba(0, 0, 0, 0.87); */
  color: rgba(0, 0, 0, 0.57) !important;
}

.event-box-pending {
  /* background: #42A5F545;
     color: #42A5F5FF!important; */
  background: #485ffd45;
  color: #485ffdff !important;
}

.event-box-reported {
  background: #085e7d45 !important;
  color: #085e7d !important;
  background: #df711b45 !important;
  color: #df711b !important;
}

.event-box-total {
  background: #22006945 !important;
  color: #220069 !important;
}

.event-box {
  font-weight: 430;
  /* color: #222222!important; */
}
</style>
